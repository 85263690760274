<template>
  <div>
    <v-row no-gutters class="px-5">
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <mf-text-input v-model="integration.job_id" disabled label="Job Id"></mf-text-input>
            </div>
          </template>
          <span>
            Id do job gerado pelo starlord
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <mf-select
                v-model="integration.config.driver"
                :rules="[notEmptyRule]"
                item-text="text"
                item-value="value"
                label="ERP"
                :items="erpList"
              ></mf-select>
            </div>
          </template>
          <span>
            Erp que será utilizado
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-autocomplete v-model="selectedUnits" multiple item-text="formated_name" outlined item-value="value" label="Lojas" :items="listUnit">
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0 && selectedUnits.length === 1">{{ item.formated_name }}</span>
                  <span v-if="index === 0 && selectedUnits.length > 1">{{ selectedUnits.length }} selecionados</span>
                </template></v-autocomplete
              >
            </div>
          </template>
          <span>
            Lista das lojas vinculadas a conta
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-row no-gutters>
                <v-col>
                  <mf-text-input v-model="integration.config.mercashop.url" :rules="[notEmptyRule]" label="Url"></mf-text-input>
                </v-col>
              </v-row>
            </div>
          </template>
          <span>
            URL para comunicar com a API.
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-row no-gutters>
                <v-col>
                  <mf-text-input v-model="integration.config.mercashop.secret_key" :rules="[notEmptyRule]" label="Secret Key"></mf-text-input>
                </v-col>
              </v-row>
            </div>
          </template>
          <span>
            Chave de secreta de acesso para comunicar com a API, obtida no arquivo .env que está no servidor.
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-row no-gutters>
                <v-col>
                  <mf-text-input v-model="integration.cron_schedule" :rules="[notEmptyRule]" disabled label="Cron"></mf-text-input>
                </v-col>
                <v-col class="mt-3" cols="auto">
                  <v-icon color="primary" @click="dialog_cron = true">mdi-plus</v-icon>
                </v-col>
              </v-row>
            </div>
          </template>
          <span>
            Campo para configuração da cron
          </span>
        </v-tooltip>
      </v-col>
      <v-col v-if="edit" class="px-4" cols="2">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-row justify="end" no-gutters>
                <v-col>
                  <mf-toggle v-model="integration.active" label="Ativo" color="#334A58"></mf-toggle>
                </v-col>
              </v-row>
            </div>
          </template>
          <span>
            Indica se a integração está ativa
          </span>
        </v-tooltip>
      </v-col>
      <v-col cols="12" class="json-input" :class="disableJsonEditor ? 'readonly-json-editor' : ''">
        <v-jsoneditor v-model="integration.config.erp" :plus="false" height="250px" :options="editorOptions" width="300px" @error="handleError" />
      </v-col>
    </v-row>
    <dialog-cron v-model="dialog_cron" @confirmAction="SetCron"></dialog-cron>
  </div>
</template>

<script>
export default {
  components: {
    DialogCron: () => import('@/components/atomic-components/molecules/DialogCron.vue')
  },
  props: {
    units: {
      type: Array
    },
    integration: {
      type: Object,
      default: () => {}
    },
    edit: {
      type: Boolean,
      default: false
    },
    jsonError: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      erpList: [
        {
          text: 'Arius',
          value: 'arius'
        },
        {
          text: 'Avanço',
          value: 'avanco'
        },
        {
          text: 'CISS Poder',
          value: 'ciss_poder'
        },
        {
          text: 'Consinco',
          value: 'consinco'
        },
        {
          text: 'Getway',
          value: 'getway'
        },
        {
          text: 'Hipcom',
          value: 'hipcom'
        },
        {
          text: 'Intelidata',
          value: 'intelidata'
        },
        {
          text: 'Intersolid',
          value: 'intersolid'
        },
        {
          text: 'Linear',
          value: 'linear'
        },
        {
          text: 'Vr Softawere',
          value: 'vr_software'
        }
      ],
      editorOptions: {
        mode: 'text',
        mainMenuBar: false
      },
      selectedUnits: [],
      options: { mode: 'view', mainMenuBar: false },
      title: '',
      dialog_cron: false,
      notEmptyRule: v => !!v || 'Campo obrigatório',
      validJson: {
        arius: {
          base_id_order: 'number',
          diretory: 'string',
          store_id: 'string',
          seller: 'string'
        },
        avanco: {
          directory: 'string',
          fees_code: 'string'
        },
        ciss_poder: {
          url: 'string',
          user: 'string',
          password: 'string',
          company_id: 'number',
          seller_id: 'number',
          fees_code: 'string'
        },
        consinco: {
          url: 'string',
          user: 'string',
          password: 'string',
          enterprise_token: 'string',
          store_token: 'string',
          id_store: 'number',
          payment_condition_code: 'number',
          seller_id: 'number'
        },
        getway: {
          url: 'string',
          user: 'string',
          password: 'string',
          company_id: 'number',
          channel_sell_seq: 'number',
          cgo: 'number',
          number_segment_sales: 'number'
        },
        hipcom: {
          url: 'string',
          id_store: 'number',
          user: 'string',
          password: 'string',
          base_id_order: 'number',
          sku_freight: 'string'
        },
        intelidata: {
          url: 'string',
          type_freight_code: 'string',
          seller: 'string',
          authorization_code: 'string'
        },
        intersolid: {
          base_id_order: 'number',
          url: 'string',
          fees_code: 'string'
        },
        linear: {
          base_id_order: 'number',
          directory: 'string',
          store_id: 'string',
          document_type: 'number'
        },
        vr_software: {
          url: 'string',
          key: 'string',
          version: 'string'
        }
      }
    }
  },
  computed: {
    listUnit() {
      if (this.units) {
        return this.units
      }
      return []
    },
    disableJsonEditor() {
      return this.integration.config.driver === ''
    }
  },
  watch: {
    'integration.config.erp': {
      handler() {
        this.validate()
      },
      deep: true
    },
    'integration.config.driver': {
      handler() {
        this.validate()
      },
      deep: true
    },
    selectedUnits() {
      if (this.selectedUnits.length > 0) {
        this.integration.config.mercashop.units = this.selectedUnits.join(',')
      }
    }
  },
  mounted() {
    if (this.edit) {
      this.selectedUnits = this.integration.config.mercashop.units.split(',')
    }
  },
  methods: {
    validate() {
      const error = !this.validateJson(this.integration.config.erp)
      if (error) {
        const nameErp = this.erpList.find(item => item.value === this.integration.config.driver)?.text

        this.jsonError.error = error
        this.jsonError.detail = `O JSON do ERP ${nameErp} não está formatado corretamente.`
      } else {
        this.jsonError.error = false
        this.jsonError.detail = ''
      }
    },
    validateJson(json) {
      if (!json || Object.keys(json).length === 0) {
        return false
      }
      let reference = this.validJson[this.integration.config.driver]
      for (let field in reference) {
        if (!(field in json) || (typeof json[field] !== 'string' && typeof json[field] !== 'number')) {
          return false
        }
      }
      return true
    },
    handleError(error) {
      this.jsonError.error = error !== ''
    },
    generateGenericToken() {
      return Math.random()
        .toString(36)
        .slice(-6)
    },
    SetCron(cron) {
      this.integration.cron_schedule = cron
      this.dialog_cron = false
    }
  }
}
</script>

<style lang="scss" scoped>
.json-input {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.readonly-json-editor {
  pointer-events: none;
  opacity: 0.6;
}
</style>
